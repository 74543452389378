import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
const IndexPage = (props) => (
  <Layout>
  <div className="wrapper">
    <BackgroundImage
      className="masthead"
      fluid={props.data.indexImage.childImageSharp.fluid}
    >
      <div className="black-overlay">
        <div className="content-box">
          <h1 style={{fontSize:"140px"}}>ALPHAFI</h1>
          <br/>
          <h1>FINANCE, INVESTING AND TECHNOLOGY.</h1>
          <br/>
          <h1>COMING SOON</h1>
        </div>
      </div>
    </BackgroundImage>
  </div>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "pexels-pixabay-210607.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;